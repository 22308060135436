/*
    Author: Paul Le Gall
    Project: Online Portfolio
    Year: 2022

    Description:
    This CSS file is part of my online portfolio project, created in 2022.
    It contains styles and design elements for the portfolio website.
*/

.bg_patch {
    background-attachment: scroll;
}
.normal_bg {
    background-attachment: fixed;
}