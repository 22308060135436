/*
    Author: Paul Le Gall
    Project: Online Portfolio
    Year: 2022

    Description:
    This CSS file is part of my online portfolio project, created in 2022.
    It contains styles and design elements for the portfolio website.
*/

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: rgba(255, 255, 255, 0.25);
    background: linear-gradient(180deg, rgba(255,255,255,0.05) 100%, rgba(0,0,0,0) 100%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    z-index: 100;
    display: grid;
    grid-template-columns: 350px auto 38vw;
    grid-template-rows: 100%;
    align-items: center;
    user-select: none;
}

.text_grid {
    grid-column-start: 3;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.text {
    margin: 0;
    text-align: center;
    font-family: "redhat";
    font-size: 1.8rem;
    font-weight: 500;
    user-select: none;
    color: aliceblue;
    flex-grow: 1;
}
.brand {
    font-size: 2.5rem;
    grid-column-start: 1;
}

@font-face {
    font-family: "redhat";
    src: url("../fonts/RedHatDisplay-VariableFont_wght.ttf");
}


@media (min-resolution: 2dppx) {
    .header {
        grid-template-columns: 350px auto 50vw;
    }
}


@media (max-width: 768px) {
    .header {
        grid-template-columns: 100vw;
        grid-template-rows: 1fr 1fr;
    }
    .brand_grid {
        grid-column-start: 1;
        grid-row-start: 1;
        justify-self: center;
        align-self: center;
        text-align: center;
    }
    .brand {
        font-size: 3rem;
        text-justify: center;
        text-align: center;
        font-weight: 800;
        padding-top: 1.5vh;
    }
    .text_grid {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        grid-column-start: 1;
        grid-row-start: 2;
    }
    .text {
        font-size: 1rem;
        text-align: center;
        flex-grow: 1;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .header {
        grid-template-columns: 100vw;
        grid-template-rows: 1fr 1fr;
    }
    .brand_grid {
        grid-column-start: 1;
        grid-row-start: 1;
        justify-self: center;
        align-self: center;
        text-align: center;
    }
    .brand {
        font-size: 3rem;
        text-justify: center;
        text-align: center;
        font-weight: 800;
        padding-top: 1.5vh;
    }
    .text_grid {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        grid-column-start: 1;
        grid-row-start: 2;
    }
    .text {
        font-size: 1rem;
        text-align: center;
        flex-grow: 1;
    }
}