/*
    Author: Paul Le Gall
    Project: Online Portfolio
    Year: 2022

    Description:
    This CSS file is part of my online portfolio project, created in 2022.
    It contains styles and design elements for the portfolio website.
*/

.project {
    display: grid;
    background-image: url("../img/project_bg.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -400;
    height: 110vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    user-select: none;
    color: aliceblue;
}

.legacy_project {
    min-height: 40vh;
    width: 35.2vw;
    display: flex;
    flex-direction: column;
    justify-content: top;
    text-align: left;
    font-family: "redhat";
    justify-self: center;
    align-self: center;
    border-radius: 25px;
    user-select: none;
}

.projects_text {
    padding: 3.8vh;
    padding-top: 1.9vh;
    background: linear-gradient(180deg, rgba(255,255,255,0.08) 100%, rgba(0,0,0,0) 100%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    border-radius: 25px;
}

.legacy_title {
    font-family: "redhat";
    font-size: 3vh;
    font-weight: 800;
    margin-bottom: 0.1vh;
}

.legacy_project_title {
    font-family: "redhat";
    font-size: 2.2vh;
    font-weight: 500;
}

.legacy_explain {
    font-family: "redhat";
    font-size: 1.2vh;
    font-weight: 300;
    text-align: left;
    text-justify: bottom;
    margin: 0;
}

.active_project {
    min-height: 40vh;
    width: 35.2vw;
    display: flex;
    flex-direction: column;
    justify-content: top;
    text-align: left;
    font-family: "redhat";
    justify-self: center;
    align-self: center;
    user-select: none;
}

.active_title {
    font-family: "redhat";
    font-size: 3vh;
    font-weight: 800;
    margin-bottom: 1.2vh;
}

.active_project_title {
    font-family: "redhat";
    font-size: 2.2vh;
    font-weight: 500;
}

.active_explain {
    font-family: "redhat";
    font-size: 1.2vh;
    font-weight: 300;
}


.wip {
    padding-right: 1.5vh;
    font-family: "redhat";
    font-size: 1.2vh;
    font-weight: 300;
    text-align: right;
    text-justify: bottom;
    margin: 0;
}

.margin_zero {
    margin: 0;
}



@font-face {
    font-family: "redhat";
    src: url("../fonts/RedHatDisplay-VariableFont_wght.ttf");
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .project {
        grid-template-columns: cover;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .legacy_project, .active_project {
        width: 90vw;
    }
    .legacy_title, .active_title {
        font-size: 2.5vh;
    }

    .legacy_project_title, .active_project_title {
        font-size: 1.8vh;
    }

    .legacy_explain, .active_explain {
        font-size: 1vh;
    }
}

@media (max-width: 1023px) {
    .project {
        grid-template-columns: cover;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .legacy_project, .active_project {
        width: 90vw;
    }
    .legacy_title, .active_title {
        font-size: 2.5vh;
    }

    .legacy_project_title, .active_project_title {
        font-size: 1.8vh;
    }

    .legacy_explain, .active_explain {
        font-size: 1vh;
    }
}