/*
    Author: Paul Le Gall
    Project: Online Portfolio
    Year: 2022

    Description:
    This CSS file is part of my online portfolio project, created in 2022.
    It contains styles and design elements for the portfolio website.
*/

.about {
    display: grid;
    min-height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-rows: 100px auto;
    user-select: none;
    color: aliceblue;
    background-image: url("../img/contact.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -500;
}

.contact_section {
    grid-row-start: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "redhat";
    font-size: 1.5rem;
    font-weight: 300;
    text-align: center;
    user-select: none;
    margin-bottom: 5em;
}

.title {
    margin: 0;
    margin-top: 3em;
    margin-bottom: 2em;
    font-size: 4rem;
    font-weight: 800;
    font-family: "redhat";
    user-select: none;
}

.contact_sub_section {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.contact_ways {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 3.5vw;
    margin-right: 3.5vw;
    text-align: center;
    user-select: none;
}

.contact_link {
    user-select: text;
}

.download_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15em;
    user-select: none;
    font-family: "redhat";
    font-size: 1rem;
    font-weight: 300;
    text-align: center;
    background: linear-gradient(180deg, rgba(255,255,255,0.0) 100%, rgba(0,0,0,0) 100%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    border-radius: 25px;
    padding: 30px;
}

.download {
    margin: 0;
    padding: 15px;
    border-radius: 10px;
    font-size: 1.5rem;
    font-style: italic;
    cursor: pointer;
}

.download_url:link, .download_url:visited {
    color: aliceblue;
    text-decoration: none;
}

.margin_zero {
    margin: 0;
}

::selection {
    background: rgba(255, 255, 255, 0.3);
}
::-moz-selection {
    background: rgba(255, 255, 255, 0.3);
}

@font-face {
    font-family: "redhat";
    src: url("../fonts/RedHatDisplay-VariableFont_wght.ttf");
}

@media (max-width: 768px) {
    .about {
        grid-template-columns: 100vw;
    }
    .title {
        padding-left: 0;
        font-size: 5vh;
        font-weight: 600;
    }
    .contact_section {
        font-size: 1.2rem;
    }
    .contact_ways {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 2em;
    }
    .download_section {
        margin-top: 5em;
    }
    .download {
        font-size: 1.2rem;
    }
    .contact_sub_section {
        flex-direction: column;
    }
    .contact_link {
        margin-bottom: 1em;
    }
    .download_section {
        padding: 10px;
        font-size: 1.5vh;
        margin-left: 2em;
        margin-right: 2em;
        margin-bottom: 2em;
        font-weight: 300;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .about {
        grid-template-columns: 100vw;
    }
    .title {
        padding-left: 0;
        font-size: 5vh;
        font-weight: 600;
    }
    .contact_section {
        font-size: 1.2rem;
    }
    .contact_ways {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 2em;
    }
    .download_section {
        margin-top: 5em;
    }
    .download {
        font-size: 1.2rem;
    }
    .contact_sub_section {
        flex-direction: column;
    }
    .contact_link {
        margin-bottom: 1em;
    }
    .download_section {
        padding: 10px;
        font-size: 1.5vh;
        margin-left: 2em;
        margin-right: 2em;
        margin-bottom: 2em;
        font-weight: 300;
    }
}