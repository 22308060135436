/*
    Author: Paul Le Gall
    Project: Online Portfolio
    Year: 2022

    Description:
    This CSS file is part of my online portfolio project, created in 2022.
    It contains styles and design elements for the portfolio website.
*/

.portfolio_grid {
    min-height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-rows: 100px auto;
    background-image: url("./img/bg.jpg");
    background-repeat: no-repeat;
    background-size: 100vw;
    background-position: top;
    z-index: -100;
}

@media (max-width: 1024px) {
    .portfolio_grid {
        background-size: auto 100vh;
        grid-template-columns: 100vw;
    }
}
