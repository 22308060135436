/*
    Author: Paul Le Gall
    Project: Online Portfolio
    Year: 2022

    Description:
    This CSS file is part of my online portfolio project, created in 2022.
    It contains styles and design elements for the portfolio website.
*/

.profile {
    display: grid;
    min-height: 100vh;
    grid-template-rows: 100px calc(150vh - 100px) 50vh;
    grid-template-columns: 1fr 1fr;
    user-select: none;
    background-image: url("../img/profile_bg.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -200;
    color: aliceblue;
}

.left_box_top {
    display: flex;
    grid-column-start: 1;
    grid-row-start: 2;
    font-family: "redhat";
    flex-direction: column;
    justify-content: top;
    align-items: left;
    text-align: left;
    margin-left: 8vh;
    font-size: 1.8vh;
    font-weight: 300;
    margin-top: 15vh;
}

.right_box_top {
    display: flex;
    grid-column-start: 2;
    grid-row-start: 2;
    font-family: "redhat";
    flex-direction: column;
    justify-content: top;
    align-items: right;
    text-align: right;
    margin-right: 8vh;
    font-size: 1.8vh;
    font-weight: 300;
    list-style: none;
    margin-top: 15vh;
}

.left_box_bottom {
    display: flex;
    grid-column-start: 1;
    grid-row-start: 3;
    font-family: "redhat";
    flex-direction: column;
    justify-content: top;
    align-items: left;
    text-align: left;
    margin-left: 8vh;
    font-size: 1.8vh;
    font-weight: 300;
}

.right_box_bottom {
    display: flex;
    grid-column-start: 2;
    grid-row-start: 3;
    font-family: "redhat";
    flex-direction: column;
    justify-content: top;
    align-items: right;
    text-align: right;
    margin-right: 8vh;
    font-size: 1.8vh;
    font-weight: 300;
    list-style: none;
}

.margin_zero {
    margin: 0;
}

.list_style_none {
    list-style: none;
}



@font-face {
    font-family: "redhat";
    src: url("../fonts/RedHatDisplay-VariableFont_wght.ttf");
}

@media (max-width: 768px) {
    .profile {
        grid-template-columns: 50vw 50vw;
    }
    .left_box_top {
        margin-left: 0;
        padding-left: 3.5vw;

    }
    .right_box_top {
        margin-right: 0;
        padding-right: 3.5vw;
    }
    .left_box_bottom {
        margin-left: 0;
        padding-left: 3.5vw;
    }
    .right_box_bottom {
        margin-right: 0;
        padding-right: 3.5vw;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .profile {
        grid-template-columns: 50vw 50vw;
    }
    .left_box_top {
        margin-left: 0;
        padding-left: 3.5vw;

    }
    .right_box_top {
        margin-right: 0;
        padding-right: 3.5vw;
    }
    .left_box_bottom {
        margin-left: 0;
        padding-left: 3.5vw;
    }
    .right_box_bottom {
        margin-right: 0;
        padding-right: 3.5vw;
    }
}