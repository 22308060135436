/*
    Author: Paul Le Gall
    Project: Online Portfolio
    Year: 2022

    Description:
    This CSS file is part of my online portfolio project, created in 2022.
    It contains styles and design elements for the portfolio website.
*/

.about {
    display: grid;
    height: calc(100vh - 100px);
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    user-select: none;
    color: aliceblue;
}

.welcome_box {
    height: 29vh;
    width: 35vw;
    margin-bottom: 23vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    font-family: "redhat";
    justify-self: center;
    align-self: center;
    background: linear-gradient(180deg, rgba(255,255,255,0.08) 100%, rgba(0,0,0,0) 100%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    border-radius: 25px;
    user-select: none;
}

.welcome {
    padding-left: 3.12vw;
    font-family: "redhat";
    font-size: 3.4vh;
    font-weight: 500;
}

.title {
    padding-top: 0.61vh;
    padding-left: 3.12vw;
    padding-bottom: 0.61vh;
    font-family: "redhat";
    font-size: 3.92vh;
    font-weight: 300;
}

.description {
    padding-left: 3.12vw;
    font-family: "redhat";
    font-size: 1.2em;
    font-weight: 300;
    padding-right: 2vh;
}

.welcome_picture {
    height: 46vh;
    width: 46vh;
    margin-bottom: 13.8vh;
    margin-right: 25vh;
    display: grid;
    font-family: "redhat";
    justify-self: center;
    align-self: center;
    background: linear-gradient(180deg, rgba(255,255,255,0.05) 100%, rgba(0,0,0,0) 100%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    border-radius: 550px;
    user-select: none;
}

.memoji {
    align-self: center;
    justify-self: center;
    height: 38vh;
    padding-bottom: 7.5vh;
}

.margin_zero {
    margin: 0;
}



@font-face {
    font-family: "redhat";
    src: url("../fonts/RedHatDisplay-VariableFont_wght.ttf");
}

@media (max-width: 768px) {
    .about {
        grid-template-columns: auto;
    }
    .welcome {
        padding-top: 1vh;
        font-size: 3.5vh;
        font-weight: 600;
        padding-left: 3.5vw;
    }
    .title {
        padding-left: 3.5vw;
        font-size: 3.2vh;
        font-weight: 500;
    }

    .welcome_box {
        z-index: 1;
        width: 90vw;
        min-height: 30vh;
        margin-bottom: 10vh;
    }
    .description {
        padding-left: 3.5vw;
        font-size: 1.2em;
        font-weight: 300;
        padding-right: 2vh;
    }
    .welcome_picture {
        position: absolute;
        width: 50vw;
        height: 50vw;
        margin-right: 0;
        top: 20vh;
        left: 50%;
        transform: translate(-50%);
        background-color: transparent;
        backdrop-filter: blur(0);
        -webkit-backdrop-filter: blur(0);
        box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
        background: transparent;

    }
    .memoji {
        height: 30vw;
        width: 30vw;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .about {
        grid-template-columns: auto;
    }
    .welcome {
        padding-top: 0;
        font-size: 5vh;
        font-weight: 600;
        padding-left: 5vw;
    }
    .title {
        padding-left: 5vw;
        font-size: 4.1vh;
        font-weight: 500;
    }

    .welcome_box {
        z-index: 1;
        width: 90vw;
        min-height: 30vh;
        margin-bottom: 10vh;
    }
    .description {
        padding-left: 5vw;
        font-size: 1.5em;
        font-weight: 300;
        padding-right: 2.5vh;
    }
    .welcome_picture {
        position: absolute;
        width: 50vw;
        height: 50vw;
        margin-right: 0;
        top: 10vh;
        left: 50%;
        transform: translate(-50%);
        background-color: transparent;
        backdrop-filter: blur(0);
        -webkit-backdrop-filter: blur(0);
        box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
        background: transparent;

    }
    .memoji {
        height: 30vw;
        width: 30vw;
    }
}